<template>
  <el-card title="添加风险">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <b-row
        class="mr10"
      >
        <b-col md="6">
          <el-form-item
            label="风险名称"
            prop="riskName"
            :rules="[ { required: true, message: '风险名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.riskName"
              placeholder="请输入风险名称"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="风险编号"
            prop="code"
            :rules="[ { required: true, message: '风险编号不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
              placeholder="请输入风险编号"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="风险类别"
          >
            <el-select
              v-model="form.riskTypeId"
              class="mr10"
              placeholder="请选择风险类别"
            >
              <el-option
                v-for="(item,index) in riskTypeOptions"
                :key="index"
                :label="item.riskTypeName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="风险系数"
          >
            <el-input
              v-model.number="form.riskFactor"
              type="number"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="风险等级"
          >
            <el-select
              v-model="form.riskLevel"
              class="mr10"
              placeholder="请选择资产大类"
            >
              <el-option
                v-for="(item,index) in riskOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="风险描述">
            <el-input
              v-model="form.description"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="处置建议">
            <el-input
              v-model="form.disposalAdvice"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="风险详情">
            <el-input
                v-model="form.explanation"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="个人权益综合影响说明">
            <el-input
              v-model="form.complexAffectRemark"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="安全事件可能性说明">
            <el-input
              v-model="form.securityIncidentRemark"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="个人权益响度"
          >
            <el-table
              border
              fit
              highlight-current-row
              :data="tableData1"
              stripe
            >
              <el-table-column
                label="项"
                prop="title"
              />
              <el-table-column
                label="值"
                prop="title"
              >
                <template slot-scope="{row}">
                  <el-input
                    v-model.number="form[row.prop]"
                    type="number"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="安全事件可能性"
            prop="productIdList"
          >
            <el-table
              border
              fit
              highlight-current-row
              :data="tableData2"
              stripe
            >
              <el-table-column
                label="项"
                prop="title"
              />
              <el-table-column
                label="值"
                prop="title"
              >
                <template slot-scope="{row}">
                  <el-input
                    v-model.number="form[row.prop]"
                    type="number"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-card>
</template>
<script>
import { saveOrUpdateRisk, GetDetailInfo, GetRiskTypeOptions } from '@/api/assess/risk'
import { error, success } from '@core/utils/utils'

export default {
  data() {
    return {
      loading: false,
      paramsInitType: ['autonomy', 'riskTypeId', 'differentialTreatment', 'complexAffectLevel', 'securityIncident', 'securityIncidentLevel', 'complexAffect', 'damagedReputation', 'processingFlow', 'propertyDamage', 'riskFactor', 'riskLevel', 'securityPosture', 'technicalMeasures', 'thirdParty'],
      form: {
        id: 0,
        riskName: '',
        riskTypeId: '',
        riskFactor: '',
        explanation: '',
        riskLevel: '',
        securityIncidentRemark: '',
        disposalAdvice: '',
        autonomy: '',
        differentialTreatment: '',
        damagedReputation: '',
        propertyDamage: '',
        complexAffect: '',
        complexAffectLevel: '',
        technicalMeasures: '',
        processingFlow: '',
        thirdParty: '',
        description: '',
        securityPosture: '',
        securityIncident: '',
        securityIncidentLevel: '',
        code: '',
        complexAffectRemark: '',
      },
      tableData1: [
        { title: '影响个人自主决定权', prop: 'autonomy', type: 'int' },
        { title: '引发差别性待遇', prop: 'differentialTreatment', type: 'int' },
        { title: '个人名誉受损或遭受精神压力', prop: 'damagedReputation', type: 'int' },
        { title: '个人财产受损', prop: 'propertyDamage', type: 'int' },
        { title: '个人权益综合影响系数', prop: 'complexAffect', type: 'int' },
        { title: '个人权益综合影响度等级', prop: 'complexAffectLevel', type: 'int' },
      ],
      tableData2: [
        { title: '网络环境与技术措施', prop: 'technicalMeasures', type: 'int' },
        { title: '个人信息处理流程', prop: 'processingFlow', type: 'int' },
        { title: '参与人员与第三方', prop: 'thirdParty', type: 'int' },
        { title: '业务特点和规模及安全态势', prop: 'securityPosture', type: 'int' },
        { title: '安全事件可能性系数', prop: 'securityIncident', type: 'int' },
        { title: '安全事件可能性等级', prop: 'securityIncidentLevel', type: 'int' },
      ],
      riskOptions: [
        { label: '极低', value: 1 },
        { label: '低', value: 2 },
        { label: '中', value: 3 },
        { label: '高', value: 4 },
        { label: '严重', value: 5 },
      ],
      riskTypeOptions: [],
    }
  },
  created() {
    this.form.id = this.$route.query.id ? Number(this.$route.query.id) : 0
    this.getRiskTypeOptions()
    if (this.form.id) {
      this.getDetail()
    }
  },
  mounted() {
    this.navActiveInit(1, '风险管理', '评估管理')
  },
  destroyed() {
    this.navActiveInit(2, '风险管理', '评估管理', '/risk')
  },
  methods: {
    getDetail() {
      GetDetailInfo({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          for (const i in this.form) {
            if (this.paramsInitType.indexOf(i) !== -1) {
              this.form[i] = this.form[i] ? Number(this.form[i]) : ''
            }
          }
        }
      })
    },
    getRiskTypeOptions() {
      GetRiskTypeOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.riskTypeOptions = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          for (const i in this.form) {
            if (this.paramsInitType.indexOf(i) !== -1) {
              this.form[i] = this.form[i] ? Number(this.form[i]) : 0
            }
          }
          saveOrUpdateRisk(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
        return false
      })
    },
    back() {
      this.$router.push('/risk')
    },
  },
}
</script>
<style scoped>

</style>
